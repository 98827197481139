@import 'custom';
@import 'react-toggle';
@import 'material-loader';
@import 'fonts';
@import '../../../node_modules/bootstrap/scss/bootstrap';

.table th,
.table td {
  font-size: 14px;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: visible !important;
  }
}

@keyframes fading {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .1;
  }
}


.homepage-announcement .announcement-content {
  p {
    margin: 0;
  }
}

.ql-align-left {
  text-align: left;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}