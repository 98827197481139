$path: '../fonts/HelveticaNeue' !default;
$helveticaFontFamily: 'HelveticaNeue';
$helveticaNeue200: 'HelveticaNeueLTW02-25UltLt';
$helveticaNeue200Italic: 'HelveticaNeueLTW02-26UltLtIt';
$helveticaNeue300: 'HelveticaNeueLTW02-35Thin';
$helveticaNeue300Italic: 'HelveticaNeueLTW02-36ThinIt';
$helveticaNeueNormal: 'HelveticaNeueLTW01-45Light';
$helveticaNeueNormalItalic: 'HelveticaNeueLTW01-46LtIt';
$helveticaNeue500: 'HelveticaNeueLTW02-55Roman';
$helveticaNeue500Italic: 'HelveticaNeueLTW02-56Italic';
$helveticaNeue600: 'HelveticaNeueLTW02-65Medium';
$helveticaNeue600Italic: 'HelveticaNeueLTW02-66MdIt';
$helveticaNeue700: 'HelveticaNeueLTW02-75Bold';
$helveticaNeue700Italic: 'HelveticaNeueLTW02-76BoldIt';
$helveticaNeue800: 'HelveticaNeueLTW02-85Heavy';
$helveticaNeue800Italic: 'HelveticaNeueLTW02-86HvIt';
$helveticaNeue900: 'HelveticaNeueLTW02-95Black';
$helveticaNeue900Italic: 'HelveticaNeueLTW02-96BlkIt';

@mixin fontFace($fontFamily, $url, $fontWeight: normal, $fontStyle:normal) {
  @font-face {
    font-family: $fontFamily;
    font-weight: $fontWeight;
    font-style: $fontStyle;
    src: url('#{$url}.eot'); /* IE9 Compat Modes */
    src: url('#{$url}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$url}.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$url}.woff') format('woff'), /* Pretty Modern Browsers */
    url('#{$url}.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$url}.svg#svgFontName') format('svg'); /* Legacy iOS */
  }
}

@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue200}', 200, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue200Italic}', 200, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue300}', 300, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue300Italic}', 300, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeueNormal}', 400, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeueNormalItalic}', 400, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeueNormal}', 500, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeueNormalItalic}', 500, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue600}', 600, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue600Italic}', 600, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue700}', 700, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue700Italic}', 700, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue800}', 800, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue800Italic}', 800, italic);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue900}', 900, normal);
@include fontFace($helveticaFontFamily, '#{$path}/#{$helveticaNeue900Italic}', 900, italic);
