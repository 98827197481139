@import '_colors';

$offset: 187;
$duration: 1.4s;

.material-spinner {
  animation: material-spinner $duration linear infinite;
}

.material-spinner.button-spinner {
  margin-right: .5em;
}

@keyframes material-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.material-path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
}

.material-path.button-spinner {
  animation: dash $duration ease-in-out infinite,
    button-spinner-colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: $pwcRed;
  }
  25% {
    stroke: $pwcYellow;
  }
  50% {
    stroke: $pwcRed;
  }
  75% {
    stroke: $pwcOrange;
  }
  100% {
    stroke: $pwcYellow;
  }
}

@keyframes button-spinner-colors {
  0% {
    stroke: $pwcYellow;
  }
  25% {
    stroke: $pwcOrange;
  }
  50% {
    stroke: $pwcYellow;
  }
  75% {
    stroke: $pwcOrange;
  }
  100% {
    stroke: $pwcYellow;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
